import React, { Component } from 'react';
import Globe from 'globe.gl';
import LocationCard from './LocationCard';
import pointsData from '../pointsData.json';
import './styles/globalStyles.css';
import PartnersPortfolio from './PartnersPortfolio';

class EarthGlobe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCard: null,
      hoveredPoint: null,
      tooltipData: null,
      tooltipTimeout: null,
    };
    this.mount = React.createRef();
    this.handleGlobalClick = this.handleGlobalClick.bind(this);
  }

  componentDidMount() {
    this.initializeGlobe();
    document.addEventListener('click', this.handleGlobalClick);
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    this.cleanupGlobe();
    document.removeEventListener('click', this.handleGlobalClick);
    window.removeEventListener('resize', this.handleResize);
  }

  initializeGlobe = () => {
    this.globe = Globe()(this.mount.current)
      .width(this.mount.current.clientWidth)
      .height(this.mount.current.clientHeight)
      .globeImageUrl('\earth-night.jpg')
      .pointsData(pointsData)
      .pointAltitude(0.001)
      .pointColor((d) => {
        // Change color for specific cities
        if (['ny', 'ld', 'db'].includes(d.id)) {
          return 'red'; // Red color for NYC, London, and Dubai
        }
        return 'lightgreen'; // Default color for other points
      })
      .pointRadius(this.getPointRadius)
      .pointResolution(900)
      .onPointClick(this.handlePointClick);

    this.configureCameraAndControls();
  };
  getPointRadius = (d) => {
    const isMobile = window.innerWidth <= 768;
    if (['ny', 'ld', 'db'].includes(d.id)) {
      return isMobile ? 1.5 : 1.0; // Larger radius for NYC, London, and Dubai on mobile
    }
    return isMobile ? 1.0 : 0.7; // Default radius for other points on mobile
  };

  configureCameraAndControls = () => {
    const { camera, controls } = this.globe;
    camera().position.set(50, 90, 250);
    controls().autoRotate = true;
    controls().autoRotateSpeed = 0.2;
  };

  cleanupGlobe = () => {
    if (this.globe) {
      this.globe._destructor();
    }
    this.clearTooltipTimeout();
  };

  handleResize = () => {
    if (this.globe) {
      this.globe.width(this.mount.current.clientWidth);
      this.globe.height(this.mount.current.clientHeight);
    }
  };

  handleGlobalClick(event) {
    if (this.mount.current.contains(event.target) && !event.target.__data__) {
      this.setState({ activeCard: null });
      this.props.setShowMenu(false);
    }
  }

handlePointClick = (point) => {
  if (this.state.activeCard !== point) {
    this.setState({ activeCard: point });
    this.props.setShowMenu(true);
  }
};

  clearTooltipTimeout = () => {
    if (this.state.tooltipTimeout) {
      clearTimeout(this.state.tooltipTimeout);
    }
  };

  render() {
    const { activeCard } = this.state;
    const currentYear = new Date().getFullYear();
    return (
      <div className="earth-globe-container" style={{ width: '100%', height: '100%' }}>
        <div ref={this.mount} className="earth-globe-mount" style={{ width: '100%', height: '100%' }} />
        {/* this.renderTooltip() */}
        {activeCard && <LocationCard data={activeCard} />}
        <div className='rubicon-contain'>
        <h1 className="rubicon-title">RUBICON VENTURES</h1>
        <h2 className="rubicon-subtitle">EARLY STAGE CAPITAL. STRATEGY. ADVISORY.</h2>
        </div>
        <div className="footer">
          <PartnersPortfolio />
          <p>© {currentYear} Rubicon Ventures. All rights reserved.</p>
        </div>
      </div>
    );
  }
}

export default EarthGlobe;