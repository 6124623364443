import React from 'react'
import './styles/globalStyles.css';
const PartnersPortfolio = () => {
  // const images = Array.from({ length: 16 }, (_, index) => `rubicon-logos/rubicon-logos-w-${index + 1}.png`);
  
  const weburls = [
    "https://256mkt.com/",
    "https://www.yellowcapital.com/",
    "https://www.u-topia.com/",
    "https://solana.com/",
    "https://www.peregrine-digital.com/",
    "https://www.nascar.com/",
    "https://meteora.ag/",
    "https://matterblock.com/",
    "https://lemonadefashion.com/discover",
    "https://jup.ag/",
    "https://www.hellomoon.io/developers",
    "https://x.com/GenZenVFX",
    "https://www.g20.group/",
    "https://www.fjordfoundry.com/",
    "https://schizoterminal.com/",
    "https://www.alphakek.ai/",
  ]
  return (

<div className="marquee marquee--8 ">
{weburls.map((href, index) => (

<a key={index} href={href} className="marquee__item" target='_blank' rel='noopener noreferrer'>
  <img className="marquee__image"    src={`rubicon-logos/rubicon-logos-w-${index + 1}.png`}    alt={`Image ${index + 1}`}/>
 </a>
  

))}
</div>


  )
}

export default PartnersPortfolio
